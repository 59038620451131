$blue: #00B2EB;
$gray: #BDBDBD;

/* archivo-black-regular - latin */
@font-face {
    font-family: 'Archivo Black';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/archivo-black-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Archivo Black Regular'), local('ArchivoBlack-Regular'),
    url('/fonts/archivo-black-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/archivo-black-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/archivo-black-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/archivo-black-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/archivo-black-v9-latin-regular.svg#ArchivoBlack') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* cutive-mono-regular - latin */
@font-face {
    font-family: 'Cutive Mono';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/cutive-mono-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Cutive Mono Regular'), local('CutiveMono-Regular'),
    url('/fonts/cutive-mono-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/cutive-mono-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/cutive-mono-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/cutive-mono-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/cutive-mono-v8-latin-regular.svg#CutiveMono') format('svg'); /* Legacy iOS */
    font-display: swap;
}


.font-archivo-black {
    font-family: 'Archivo Black', sans-serif;
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 12px;
}

body {
    padding: 8px;
}

img {
    max-width: 100%;
    height: auto;
}

.page {
    max-width: 546px;
    margin: 0 auto;
}

.top {
    height: 93px;
}

a {
    color: inherit;
}

.menu {
    background-image: url('/images/menu.png');
    height: 29px;
    line-height: 18px;
    border-left: 2px solid black;
    border-right: 2px solid black;
    padding: 0 15px;
}

.menu ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.menu ul li {
    width: 33%;
}
.menu ul li a {
    display: flex;
    height: 18px;
    align-items: center;
}

.content {
    display: grid;
    background-color: $gray;
    border-left: 2px solid #000000;
    border-right: 2px solid #000000;
}
.cols-2 {
    grid-template-columns: 235px 302px;
    grid-gap: 5px;
}

.block {
    position: relative;
    margin-bottom: 15px;
}
.block-white {
    background-color: #FFFFFF;
}

.block > p {
    margin: 0;
    padding: 10px 10px 0 10px;
}
.block > p:last-child {
    padding-bottom: 10px;
}

.block > form > p {
    margin: 0;
    padding: 10px 10px 0 10px;
}
.block > form > p:last-child {
    padding-bottom: 10px;
}

.heading {
    background-color: #000000;
    color: #FFFFFF;
    font-family: 'Archivo Black', sans-serif;
    font-size: 12px;
    line-height: 17px;
    padding: 0 5px;
    font-weight: 700;
}
.heading span::before {
    font-family: 'Archivo Black', sans-serif;
    content: "E";
    padding-right: 10px;
    font-size: 14px;
}

.block-white::after {
    content: "::";
    right: 3px;
    bottom: 1px;
    position: absolute;
}

.link-bold {
    text-decoration: none;
    color: #000000;
    font-family: Verdana, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

.form-inline {
    display: flex;
    width: 100%;
    margin: 10px 0;

    label {
        flex: 1;
        display: flex;
        align-items: center;
        height: 24px;
        position: relative;
        padding: 0 10px;
        background-color: $blue;
        justify-content: right;
    }

    span.spacer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: #ffffff;
        font-weight: bold;
        height: 24px;
        width: 24px;
    }

    input {
        border: medium none;
        height: 24px;
        max-width: 50%;
        padding: 0 5px;
    }
}

.form-inline-buttons {
    padding-left: 35%;
    padding-top: 20px;

    button {
        background-color: transparent;
        border: medium none;
        margin: 0;
        padding: 0;
        width: auto;
        font-family: 'Archivo Black', sans-serif;
        font-size: 12px;
    }
    button > img {
        margin-right: 10px;
    }
    button:hover {
        cursor: pointer;
    }
}

.form-inline-simpel {
    display: flex;


    width: 100%;
    margin: 10px 0;

    label {
        flex: 1;
        display: flex;
        align-items: center;
        height: 24px;
        position: relative;
        padding: 0 10px;
        justify-content: right;
    }

    span.spacer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: #ffffff;
        font-weight: bold;
        height: 24px;
        width: 24px;
    }

    input {
        border: medium none;
        height: 24px;
        max-width: 50%;
        padding: 0 5px;
    }
}

.company-result {
    border-bottom: 1px solid $gray;
    padding: 10px;

    p {
        padding: 0;
        margin: 0 0 10px;
    }

    .company-link {
        font-family: 'Archivo Black', sans-serif;

        a {
            text-decoration: none;
        }
    }

    .company-links {
        margin: 10px 0 0 0;
        display: flex;
        justify-content: flex-end;
        list-style: none;
    }
    .company-links li {
        margin-left: 10px;
    }
}

.company-description {
    padding: 10px 10px 0 10px;
}

.socials {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    list-style: none;
    padding: 10px;

    li {
        margin-left: 15px;
    }

    svg {
        height: 20px;
        width: 20px;
    }
    svg:hover {
        color: $blue;
    }
}

.form-group {
    padding: 10px 10px 0 10px;

    input, textarea {
        width: 90%;
    }
}
.form-contact {
    padding-bottom: 20px;
}

.footer {
    border-left: 2px solid black;
    border-right: 2px solid black;
    background-color: $blue;

    .abc {
        font-family: 'Cutive Mono', sans-serif;

        ul {
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin: 0;
            padding: 10px 10px;
        }

        a {
            color: #000000;
            text-decoration: none;
        }
    }

    .footer-nav {
        background-color: #000000;
        color: #ffffff;
        text-align: right;
        padding: 1px 3px;

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }
}

.form-inline-block {
    display: flex;
    padding: 10px 10px 0 10px;

    label {
        flex: 1;
    }


    input, textarea {
        border: medium none;
        height: 24px;
        max-width: 50%;
        padding: 0 5px;
    }
    textarea { height: 45px; }
}

.button-block-right {
    text-align: right;
    padding: 10px 10px 0 10px;
}

.hidden {
    display: none;
}

.has_error {
    padding: 0 10px;
    color: #dc3545;
}

.flashmessage {
    border-style: solid;
    border-color: black;
    border-width: 1px 2px;
    color: #ffffff;
    margin: 1rem 0.5rem;
    padding: 5px 10px;
    font-size: 12px;
    font-family: 'Archivo Black', sans-serif;
}
.notify_success {
    background-color: $blue;
}
.notify_failure {
    background-color: #b91c1c;
}

label.checkbox {
    display: flex;
    align-items: self-start;
    padding: 10px 10px 0 10px;

    input[type="checkbox"] {
        margin-right: 10px;
    }
}

.category-list {
    margin: 10px 0;
    padding-left: 25px;

    li {
        padding: 5px 0;
    }
}

@media (max-width: 768px) {
    .top {
        height: auto;
    }

    .content {
        display: block;
    }
    .block {
        margin-bottom: 0;
        padding-bottom: 15px;
    }
    .abc {
        display: none;
    }
    .footer a {
        display: block;
        margin: 5px 0;
    }
    .footer span {
        display: none;
    }

    .page-signup .news,
    .page-categories .news,
    .page-companies .news,
    .page-listing .news,
    .page-search .news
    {
        display: none;
    }

    .page-signup .signup,
    .page-listing .signup
    {
        display: none;
    }
}

.pagination-wrap nav {
    display: flex;
    justify-content: space-around;
}

.pagination {
    display: flex;
    margin: 0;
    padding: 1.5rem 0;
}

.pagination li.page-item {
    display: flex;
    height: 2rem;
    width: 2rem;
    justify-content: center;
    align-items: center;
    font-size: .875rem;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    border-left: 1px solid $gray;
}
.pagination a.page-link {
    text-decoration: none;
}

.pagination li.page-item:first-child {
    border-radius: 5px 0 0 5px;
}

.pagination li.page-item:last-child {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid $gray;
}

.pagination .active {
    background-color: $blue;
}

.company-whatsapp a img {
    max-width: 120px;
}

@import "statusbar.css";
